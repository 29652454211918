import React from "react"
import { Helmet } from "react-helmet";

import Layout from "../components/layout";
import SEO from "../components/seo";
import get from 'lodash/get'
import HomeIntro from "@components/home-intro/home-intro";
import CTAIntro from "@components/home-intro/cta-intro";
import StaticIntro from "@components/static-intro/static-intro";
import InnerIntro from "@components/inner-intro/inner-intro";
import InnerTable from "@components/inner-intro/table";
import StaticPage from "@components/static-page/static-page";
import StaticPageBanner from "@components/static-page/static-page-banner";
import Wave from "@components/wave/wave";
import HomeTextColumns from "@components/home-text-columns/home-text-columns";
import HomeBoxes from "@components/home-boxes/home-boxes";
import HomeRowImgText from "@components/home-row-img-text/home-row-img-text";
import ImgContentModule from "@components/home-row-img-text/img-content-module";
import HomeStats from "@components/home-stats/home-stats";
import HomeContact from "@components/home-contact/home-contact";
import Valuation from "@components/valuation/valuation";
import StaticPersonVideo from "@components/static-person-video/static-person-video";
import TeamListings from "@components/team-listing/team-listing";
import AreaListings from "@components/area-listing/area-listing";
import VideoListings from "@components/video-listing/video-listing";
import AllVideoListings from "@components/video-listing/all-videos";
import StaticPositions from "@components/static-position/static-position";
import OfficeListings from "@components/office-listing/office-listing";
import StaticTable from "@components/static-table/static-table";
import LandingIntro from "@components/landing-intro/landing-intro";
import InnerBanner from '@components/inner-banner/inner-banner';
import StaticInnerBanner from '@components/inner-banner/static-inner-banner';
import InnerBannerContents from '@components/inner-banner/inner-banner-content';
import StaticTimeline from "@components/static-timeline/static-timeline";
import NewsOverviewGrid from "@components/news-overview-grid/news-list";
import NewsOver from "@components/news-over/news-over";
import ValuationPage from "@components/valuation-page/valuation";
import OfficeLandingMap from "@components/office-landing-map/office-landing-map";
import AllReviews from "@components/static-reviews/static-reviews";
import StampDutyCalc from "@components/calculators/stampdutycalc/index";
import MortgageCalc from "@components/calculators/mortgagecalc/index";
import RentYield from "@components/calculators/rentalyieldcalc/index";
import AwardsListing from "@components/awards-listing"
import { graphql } from 'gatsby'
import ElfsightInstaWidgetModule from "@components/modules/ElfsightInstaWidgetModule/ElfsightInstaWidgetModule";
import FormModule from "@components/modules/FormModule/FormModule";

class CommonTemplate extends React.Component {

  render() {
    //Detail pages match
    //Default fetch

    const Page = this.props.Page
    const Modules = this.props.Modules
    const GlobalConfig = this.props.GlobalConfig
    const TeamMember = this.props.TeamMember
    const TeamSlider = this.props.TeamSlider
    const JobOpenings = this.props.JobOpenings
    const OfficeList = this.props.OfficeList
    const VideosList = this.props.VideosList
    const classAttribute = `common-page-wrap ${Page?.Pagename ? `page-${Page.Pagename.replace(/\s+/g, '-').toLowerCase()}` : ''} ${Page?.Layout || ''} ${Page?.Custom_CSS_Class || ''}`;
    return (
      <>
        <Layout popularSearch={Page.Select_Popular_Search} footerDetails={GlobalConfig}>

          <SEO title={Page.Meta_Title} description={Page.Meta_Description} />

          <Helmet
            bodyAttributes={{
              class: `homepage ${classAttribute || ''}`
            }} />



          {Modules.map((Modules, i) => {

            console.log("Modules", Modules)
            return (
              <>
                {Modules.Banner_Title != null && Modules.Banner_CTA_1_Label != null && Modules.Banner_CTA_2_Label != null && Modules.Banner_CTA_1_URL != null && Modules.Banner_CTA_2_URL != null &&
                  <HomeIntro btnclass={Modules.Button2_Class} Title={Modules.Banner_Title} CTA1Link={Modules.Banner_CTA_1_URL.URL} CTA2Link={Modules.Banner_CTA_2_URL.URL} CTA1Label={Modules.Banner_CTA_1_Label} CTA2Label={Modules.Banner_CTA_2_Label} CTAParent1={Modules.Banner_CTA_1_URL.main_parent} CTAParent2={Modules.Banner_CTA_2_URL.main_parent} search={Modules.Show_Search} review={Modules.Show_Review} />
                }
                {Modules.Banner_Title != null && Modules.Banner_CTA_1_Label != null && Modules.Banner_CTA_2_Label != null && Modules.Banner_1_Custom_Link != null && Modules.Banner_2_Custom_Link != null &&
                  <CTAIntro btnclass={Modules.Button2_Class} Title={Modules.Banner_Title} CTA1Link={Modules.Banner_1_Custom_Link} CTA2Link={Modules.Banner_2_Custom_Link} CTA1Label={Modules.Banner_CTA_1_Label} CTA2Label={Modules.Banner_CTA_2_Label} search={Modules.Show_Search} review={Modules.Show_Review} />
                }

                {Modules.Banner_Title != null && Modules.Banner_CTA_1_Label != null && Modules.Banner_CTA_2_Label != null && Modules.Banner_CTA_1_URL == null && Modules.Banner_CTA_2_URL == null && Modules.Banner_1_CTA_Hash != null && Modules.Banner_2_CTA_Hash != null &&

                  <HomeIntro btnclass={Modules.Button2_Class} Title={Modules.Banner_Title} CTA1Hash={Modules.Banner_1_CTA_Hash} CTA2Hash={Modules.Banner_2_CTA_Hash} CTA1Label={Modules.Banner_CTA_1_Label} CTA2Label={Modules.Banner_CTA_2_Label} search={Modules.Show_Search} review={Modules.Show_Review} />
                }
                {Modules.Banner_Title != null && Modules.Banner_1_CTA_Hash != null && Modules.Banner_CTA_2_URL != null && Modules.Banner_2_CTA_Hash == null &&
                  <HomeIntro btnclass={Modules.Button2_Class} Title={Modules.Banner_Title} CTA1Hash={Modules.Banner_1_CTA_Hash} CTA2Hash={Modules.Banner_2_CTA_Hash} CTA1Label={Modules.Banner_CTA_1_Label} CTA2Label={Modules.Banner_CTA_2_Label} CTAParent2={Modules.Banner_CTA_2_URL.main_parent} CTA2Link={Modules.Banner_CTA_2_URL.URL} search={Modules.Show_Search} review={Modules.Show_Review} />
                }
                {Modules.Banner_Title != null && Modules.Banner_CTA_1_Label != null && Modules.Banner_CTA_2_Label != null && Modules.Banner_CTA_1_URL == null && Modules.Banner_CTA_2_URL == null && Modules.Banner_1_CTA_Hash != null || Modules.Banner_2_CTA_Hash != null &&

                  <HomeIntro btnclass={Modules.Button2_Class} Title={Modules.Banner_Title} CTA1Hash={Modules.Banner_1_CTA_Hash} CTA2Hash={Modules.Banner_2_CTA_Hash} CTA1Label={Modules.Banner_CTA_1_Label} CTA2Label={Modules.Banner_CTA_2_Label} search={Modules.Show_Search} review={Modules.Show_Review} CTA1Link={Modules.Banner_CTA_1_URL && Modules.Banner_CTA_1_URL.URL} CTA2Link={Modules.Banner_CTA_2_URL && Modules.Banner_CTA_2_URL.URL} CTAParent1={Modules.Banner_CTA_1_URL && Modules.Banner_CTA_1_URL.main_parent} CTAParent2={Modules.Banner_CTA_2_URL && Modules.Banner_CTA_2_URL.main_parent} />
                }
                {Modules.Banner_Title != null && Modules.Banner_CTA_1_Label == null && Modules.Banner_CTA_2_Label == null &&
                  <StaticIntro Title={Modules.Banner_Title} search={Modules.Show_Search} review={Modules.Show_Review} />
                }
                {Modules.Static_Intro_Title != null && Page.Layout != "Static_Page" &&
                  <InnerIntro Title={Modules.Static_Intro_Title} Content={Modules.Static_Intro_Content} Page={Page} />
                }
                {Modules.Static_Intro_Title != null && Page.Pagename == "Chamberlains" && Page.Layout == "Static_Page" &&
                  <StaticPageBanner Alias={Page.Alias} layout={Page.Layout} Title={Modules.Static_Intro_Title} Content={Modules.Static_Intro_Content} data={TeamMember} />
                }
                {Modules.Static_Intro_Title != null && Page.Layout == "Static_Page" && Page.Pagename !== "Chamberlains" &&
                  <StaticPage Alias={Page.Alias} layout={Page.Layout} Title={Modules.Static_Intro_Title} StraplineText={Modules?.Static_Strapline_Text} Content={Modules.Static_Intro_Content} data={TeamMember} />
                }
                {Modules.Video_Background_Image != null &&
                  <Wave Title={Modules.Banner_Title} VideoBG={Modules.Video_Background_Image} VideoURL={Modules.Embed_Video_URL} Showreview={Modules.Show_Review} Showsearch={Modules.Show_Search} HTMLVideo={Modules.Upload_Video} />
                }
                {Modules.Content_Column_Left != null && Modules.Content_Column_Right != null &&
                  <HomeTextColumns ContentLeft={Modules.Content_Column_Left} ContentRight={Modules.Content_Column_Right} childpages={Modules.Child_Pages != null ? Modules.Child_Pages : ''} />
                }
                {Modules.Valuation_Title != null && Modules.Valuation_Content != null &&
                  <ValuationPage Title={Modules.Valuation_Title} Content={Modules.Valuation_Content} LeftImg={Modules.Valuation_Left_Box_Image}
                    LeftContent={Modules.Valuation_Left_Box_Content} LeftCta={Modules.Valuation_Left_Box_Cta_Label} LeftTitle={Modules.Valuation_Left_Box_Title} RightImg={Modules.Valuation_Right_Box_Image}
                    RightContent={Modules.Valuation_Right_Box_Content} RightCta={Modules.Valuation_Right_Box_Cta_Label} RightTitle={Modules.Valuation_Right_Box_Title} LeftLink={Modules.Valuation_Left_Box_CTA_Link} RightLink={Modules.Valuation_Right_Box_CTA_Link} />
                }

                {Page.Layout == "Parent_Landing_Page" &&
                  <LandingIntro Title={Modules.Intro_Title} Content={Modules.Intro_Content} VideoURL={Modules.Landing_Video_URL} VideoBG={Modules.Landing_Video_Image} />
                }
                {Modules.Static_Banner_Image != null && Modules?.button_list.length>0 &&
                  <StaticInnerBanner ctas={Modules?.button_list} Title={Modules.Static_Banner_Title} BannerImage={Modules.Static_Banner_Image.childImageSharp.fluid} VideoURL={Modules.Static_Banner_Video_URL} Caption={Modules.Static_Banner_Video_Caption}  Show_Breadcrumbs={Modules?.Show_Breadcrumbs} />
                }
                {Modules.Static_Banner_Image != null && Modules?.button_list.length<=0 &&
                  <InnerBanner Title={Modules.Static_Banner_Title} BannerImage={Modules.Static_Banner_Image.childImageSharp.fluid} VideoURL={Modules.Static_Banner_Video_URL} Caption={Modules.Static_Banner_Video_Caption}  Show_Breadcrumbs={Modules?.Show_Breadcrumbs} />
                }
                {Modules.Banner_image != null &&
                  <InnerBannerContents Title={Modules.Banner_title} BannerImage={Modules.Banner_image.childImageSharp.fluid} Ctas={Modules.Ctas} />
                }
                {Modules.Tiles != null &&
                  <HomeBoxes data={Modules.Tiles} />
                }
                {Modules.Select_Module == 'Reviews' &&
                  <HomeRowImgText />
                }
                 {Modules.ModuleType == 'ElfsightInstaWidget' &&
                  <ElfsightInstaWidgetModule ModuleData = {Modules}/>
                }
                {Modules.Select_Calculator == 'Stampduty_Calculator' &&
                  <StampDutyCalc />
                }
                {Modules.Select_Calculator == 'Mortgage_Calculator' &&
                  <MortgageCalc />
                }
                {Modules.Select_Calculator == 'Rent_to_Yield_Calculator' &&
                  <RentYield />
                }

                {Modules.Table_Content != null &&
                  <InnerTable table={Modules.Table_Content} />
                }
                {Modules.Select_Module == 'Video_Testimonials_Listing' &&
                  <VideoListings data={VideosList} cat="Video_Testimonials" />
                }
                {Modules.Select_Module == 'Video_Listing' &&
                  <AllVideoListings data={VideosList} cat="Videos" />
                }
                {Modules.Select_Module == 'Award_Video_Listing' &&
                  <VideoListings data={VideosList} cat="Award_Videos" />
                }
                {Modules.Select_Module == 'AreaGuides_Listing' &&
                  <AreaListings />
                }
                {Modules.Select_Module == 'Newsletter_Form' &&
                  <NewsOver />
                }
                {Modules.Select_Module == 'All_Reviews' &&
                  <AllReviews WidgetID={Page?.Widget_Key} OfficeList={OfficeList} />
                }
                {Modules.Select_Module == 'Office_Landing_Map' &&
                  <OfficeLandingMap />
                }
                {Modules.Select_Module == 'News_Listing' &&
                  <NewsOverviewGrid />
                }
                {Modules.History_Top != null && Modules.History_Bottom != null &&
                  <StaticTimeline historytop={Modules.History_Top} historybtm={Modules.History_Bottom} />
                }
                {Modules.Select_Module == 'Team_Slider' &&
                  <StaticPersonVideo data={TeamSlider} />
                }
                {Modules.Stats != null &&
                  <HomeStats Title={Modules.Statistics_Title} data={Modules.Stats} />
                }
                {Modules.Select_Module == "Get_In_Touch" &&
                  <HomeContact data={TeamMember} Content={GlobalConfig.Get_In_Touch_Content} />
                }
                {Modules.Select_Module == "Current_Positions" &&
                  <StaticPositions data={JobOpenings} />
                }
                {Modules.Select_Module == "Office_Listing" &&
                  <OfficeListings data={OfficeList} />
                }
                {Modules.Select_Module == "Send_CV_Module" &&
                  <HomeContact formname="careers" data={TeamMember} Content={GlobalConfig.Send_US_Form_Content} />
                }
                {Modules.Select_Module == 'Team_Listing' &&
                  <TeamListings data={TeamSlider} />
                }
                {Modules.Select_Module == 'Prime_Logo_Module' &&
                  <ImgContentModule />
                }
                {Modules.ModuleType == "FormModule" && Modules.FormList =="GetInTouchForm" &&
                    <FormModule ModuleData={Modules} />
                }

              </>
            )
          })}
        </Layout>

      </>
    )
  }
}

export default CommonTemplate
