import React, { useEffect, useState, useRef } from 'react';
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw';
import { Container, Modal } from 'react-bootstrap';

import $ from "jquery";
import PlayIcon from '@components/play-icon/play-icon';
import BackgroundImage from 'gatsby-background-image'
import loadable from '@loadable/component'
//import VideoPlay from "../../components/play/video-play";
import {GoogleReviewIconWhite} from "@components/icon/icon";
import getVideoId from 'get-video-id';
import ReactPlayer from 'react-player';
import ScrollAnimation from 'react-animate-on-scroll';

import {
  isIOS,
  isMobileOnly,
  isBrowser,
  isMobile
} from "react-device-detect";
/**
 * Assets
 */
import './styles/_index.scss'

/**
 * Components
 */

import Intro from '@components/intro/intro';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';

const InnerBanner = (props) => {
  const VideoPlay  = loadable(() => import('../play/video-play'));
  const VideoURLid = props.VideoURL && getVideoId(props.VideoURL);
  
  const [isPlay,setPlay] = useState(false);
  const [isPlaying,setPlaying] = useState(false);

  const [show, setShow] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [play, setPlayvideo] = useState(true);
  const [showHTMLVideo, setHTMLVideo] = React.useState(false);
  const [controls, setVideoControls] = useState(false);
  const [mute, setMute] = useState(true);
  const vidRef = useRef(null);
  const pausevideo = (ref) => {
      setPlayvideo(false)
      setVideoControls(false)

  }
  const playvideo = (ref) => {
      setPlayvideo(true)
      setVideoControls(false)
  }
  const showControls = () => {
      setVideoControls(true)
  }
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const trackerVideo = (event) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': url,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
      });
  }
  let AOS;
  useEffect(() => {
    const AOS = require("aos");
    AOS.init({
      once: true,
      disable: 'mobile'
    });
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });
  if (typeof window !== "undefined") {
  $(window).scroll(function () {
      var sticky = $('.sticky-header'),
          scroll = $(window).scrollTop();

      if (scroll >= 40) sticky.addClass('fixed');
      else sticky.removeClass('fixed');
  });
  }

  // console.log("PlayIcon")
  var video_url = ''
  if(props.VideoURL !=null && props.VideoURL !=''){
    var youtube_id = getVideoId(props.VideoURL);
    video_url      = "https://www.youtube-nocookie.com/embed/"+youtube_id.id;
}
  return (
    <>
       {showHTMLVideo &&
                <ScrollAnimation animateIn='animate__zoomIn' animateOnce={true} duration={0.3} delay={300}>
                    <Modal
                        show={show}
                        onHide={() => setShow(false)}
                        dialogClassName="modal-video"
                        aria-labelledby="example-custom-modal-styling-title"
                        backdropClassName="video-backdrop"
                    >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <ReactPlayer autoplay ref={vidRef} onPlay={trackerVideo(props.Title)} url={video_url} modestbranding="1"  controls={isMobile && isIOS ?true:false} autoplay={true} muted={false} playsinline={true} playing={play} onEnded={() => { setHTMLVideo(false) }} width='100%' height='100%' />
                        </Modal.Body>
                    </Modal>
                </ScrollAnimation>

            }
        <BackgroundImage
          Tag={`div`}
          className="inner-banner"
          fluid={props.BannerImage}
          style={{
            backgroundPosition: '0 0 !important',
          }}
        >
        <div className="bg-overlay"></div>
        <div className="bg-wrapper">
        {props?.Show_Breadcrumbs && <Breadcrumbs/>}
        <Intro>
           <div className="intro__text" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" >
            <h2 className="spacing-bottom-32">{props.Title}</h2>            
                <p>{props.Caption}</p>
                {(props?.ctas.length>0) && (             
                  <div className="btn-list">
                    {props?.ctas.map((cta, i) => {
                       return (
                        <a href={`/${cta?.button_link?.URL}`} className={`btn`}>{cta.button_name}</a>
                      )
                    })
                  }
                  </div>
              )}
             <p className='reviews-text'><GoogleReviewIconWhite /> <span>4.9/5</span> customer rating from 2248 Reviews</p>
            </div>
            </Intro>
        </div>
      </BackgroundImage>
      {props.VideoURL !=null && props.VideoURL != '' && VideoURLid &&
      <VideoPlay
              isOpen = { isPlay } 
              isCloseFunction = { setPlay }
              videoId={VideoURLid.id}
              isAutoPlay={1}
            />
      }
    </>
  )
}

export default InnerBanner
