import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';
import "animate.css/animate.min.css";
import { useMatch } from "@reach/router"


/**
 * Assets
 */

import './styles/_index.scss';

const StaticTimeline = (props) => {
    const awardspage = useMatch("about/awards");
    const timeline = useRef();
    const [width, setWidth] = useState(0);
    let AOS;
    useEffect(() => {
      const AOS = require("aos");
      AOS.init({
        once: true,
        disable: 'mobile'
      });
    }, [])




    const handleResize = () => {
        setWidth(0);
        setWidth(timeline.current.scrollWidth);
    }

    useEffect(() => {
        setWidth(timeline.current.scrollWidth);
        window.addEventListener('resize', handleResize);
        
        return function(){
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    return (
        <div className="static-timeline" ref={timeline}>
            <div className="timeline">
                <Container className="top-row">
                    <Row className="flex-lg-nowrap- align-items-end">
                        {props.historytop.map((item, key) => {
                        var incekey =  key+1;
                        var delaying = incekey+'00';  
                        return<>
                        <Col md="12" data-aos-easing="ease-in-out"  data-aos="fade-up" data-aos-delay={delaying}>
                            <div className="timeline__dot">
                                <div className="content">
                                    <div className="year">{item.Year}</div>
                                    <p>
                                        <h4>{item.Title}</h4>
                                        {item.Caption}
                                    </p>
                                    {item.Image !=null &&
                                        <Img fixed={item.Image.childImageSharp.fixed} alt={item.Year+" Our Hisory - Anthony Pepe"}/>
                                    }
                                </div>
                            </div>
                        </Col>
                        </>
                        })}
                        
                    </Row>
                </Container>
                <Container className="bottom-row">
                    <Row className="flex-lg-nowrap- align-items-start">
                        {props.historybtm.map((item, key) => {
                        var incekey =  key+1;
                        var delaying = incekey+'00'; 
                        return<>
                        <Col md="12" data-aos-easing="ease-in-out" data-aos-offset="-50" data-aos="fade-up" data-aos-delay={delaying}>
                            <div className="timeline__dot">
                                <div className="content">
                                    <div className="year">{item.Year}</div>
                                    <p>
                                        <h4>{item.Title}</h4>
                                        {item.Caption}
                                    </p>
                                    {item.Image !=null &&
                                        <Img fixed={item.Image.childImageSharp.fixed} alt={item.Year+" Our Hisory - Anthony Pepe"} />
                                    }
                                </div>
                            </div>
                        </Col>
                        </>
                        })}
                        
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default StaticTimeline
